body {
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  background-color: #ffffff;
  color: #46484a; }

a {
  color: #59a0d7; }

h1 {
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 4rem;
  font-weight: 600; }

h2 {
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 2.8rem;
  font-weight: 500; }

h3 {
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 2rem;
  font-weight: 500; }

h4 {
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase; }

p {
  color: #696b6e;
  font-size: .9rem; }

.divider {
  display: block;
  width: 6rem;
  height: 0.3rem;
  background-color: #dfe1e5;
  margin: 2rem auto;
}

.divider-small {
  display: block;
  width: 6rem;
  height: 1rem;
  background-color: #4bc1f0;
  margin-top: 1.5rem;
}

.divider-long {
  display: block;
  height: 0.4rem;
  background-color: #fff;
  margin: 2rem auto;
}

.form-control-custom {
  border: 0.1rem solid #dfe1e5;
  width: 100%;
  padding: 1rem;
  font-size: .9rem;
  color: #696b6e;
  border-radius: .3rem; }

.form-control-custom:focus {
  border: 0.1rem solid #59a0d7;
  outline: none; }

.emphasis {
  font-family: Georgia, Times, 'Times New Roman';
  font-style: italic;
  color: #59a0d7; }

.constrain-width {
  max-width: 45rem;
  margin: 0 auto; }

.rounded-corners {
  border-radius: .3rem; }

.pill-btn {
  border-radius: 499rem !important; }

.justify-center {
  display: flex;
  align-items: center;
  justify-content: center; }

.alt-background {
  background: #f7f7f8; }

.bg-primary {
  background-color: #59a0d7 !important; }

.bg-secondary {
  background-color: #46484a !important; }

.social {
  margin: 0 auto;
  padding: 0; }

.social li {
  display: inline-block; }

.social li a {
  display: block;
  width: 2.2rem;
  height: 2.2rem;
  margin: .4rem .1rem;
  text-align: center;
  text-decoration: none;
  background: #fff;
  color: #696b6e;
  border: 0.1rem solid #dfe1e5;
  border-radius: 499rem;
  line-height: 1.5;
  font-size: 1.4rem; }

.social li a:hover {
  color: #59a0d7;
  border-color: #59a0d7; }

.btn-primary, .btn-outline-primary, .btn-secondary, .btn-outline-secondary, .btn-white, .btn-outline-white, .btn-success, .btn-outline-success, .btn-info, .btn-outline-info, .btn-warning, .btn-outline-warning, .btn-danger, .btn-outline-danger {
  font-size: .75rem;
  text-transform: uppercase;
  font-weight: 700;
  border-radius: .3rem; }

.btn-outline-primary, .btn-outline-secondary, .btn-outline-white, .btn-outline-success, .btn-outline-info, .btn-outline-warning, .btn-outline-danger {
  border-width: 0.15rem; }

.btn-lg {
  padding: 1.1rem 2.5rem;
  font-size: .9rem; }

.btn-primary, .btn-primary:hover, .btn-primary:focus, .btn-primary:active {
  background-color: #4bc1f0;
  border-color: #4bc1f0; }

.btn-primary:hover, .btn-primary:focus, .btn-primary:active {
  background-color: #41a5c4;
  border-color: #41a5c4; }

.btn-outline-primary, .btn-outline-primary:visited {
  color: #59a0d7;
  border-color: #59a0d7;
  background: none; }

.btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary:active {
  background-color: #59a0d7;
  border-color: #59a0d7;
  color: #fff; }

.btn-secondary {
  border-color: #696b6e;
  background-color: #696b6e;
  color: #fff; }

.btn-secondary:hover, .btn-secondary:focus, .btn-secondary:active {
  border-color: #46484a;
  background-color: #46484a;
  color: #fff; }

.btn-outline-secondary, .btn-outline-secondary:visited {
  color: #696b6e;
  border-color: #696b6e;
  background: none; }

.btn-outline-secondary:hover, .btn-outline-secondary:focus, .btn-outline-secondary:active {
  border-color: #696b6e;
  background: #696b6e;
  color: #fff; }

.btn-white {
  border-color: #fff;
  background-color: #fff;
  color: #46484a; }

.btn-white:hover, .btn-white:focus, .btn-white:active {
  border-color: #dfe1e5;
  background-color: #dfe1e5;
  color: #46484a; }

.btn-outline-white, .btn-outline-white:visited {
  background: none;
  color: #fff;
  border-color: #fff; }

.btn-outline-white:hover, .btn-outline-white:focus, .btn-outline-white:active {
  color: #46484a;
  background: #fff; }

.link-white, link-white:visited {
  font-size: .75rem;
  text-transform: uppercase;
  font-weight: 700;
  border-radius: .3rem;
  border: 0;
  color: #fff; }

.link-white:hover, .link-white:focus, .link-white:active {
  color: #dfe1e5 !important; }

.navbar-custom {
  background: transparent;
  /*background-color: #eee;*/
}

a.navbar-brand, a.logo {
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #fff !important;
  font-size: 2rem;
  font-weight: bold;
  margin-top: 0; }

a.logo:hover, a.logo:active, a.logo:focus {
  text-decoration: none; }

li.nav-item .btn {
  margin-top: .2rem;
  margin-left: .4rem; }
li.nav-item .btn-white {
  color: #46484a; }

.cover-1 {
  background: #2b2d34 url(../images/cover-bg-1.jpg) center;
  background-size: cover;
  min-height: 50rem;
  height: auto;
  border-radius: 0;
  width: 100%;
  color: #fff;
  padding-top: 1rem; }
  .cover-1 h1 {
    font-weight: 200; }
  .cover-1 p.lead {
    margin: 2rem auto;
    color: rgba(255, 255, 255, 0.75); }
  .cover-1 .cover-container {
    display: table;
    height: 100%;
    min-height: 44rem;
    margin: 0 auto; }

.cover-2 {
  background: #2b2d34 url(../images/cover-bg-1.jpg) center;
  background: #2b2d34;
  background-size: cover;
  min-height: 50rem;
  height: auto;
  border-radius: 0;
  width: 100%;
  color: #fff;
  padding-top: 1rem; }
  .cover-2 p.lead {
    margin: 2rem auto;
    color: rgba(255, 255, 255, 0.75); }
  .cover-2 .cover-container {
    display: table;
    height: 100%;
    min-height: 44rem;
    margin: 0 auto; }

.cover-5 {
  background: #f7f7f8;
  min-height: 40rem;
  height: auto;
  border-radius: 0;
  width: 100%;
  color: #333;
  padding-top: 1rem; }
  .cover-5 a.navbar-brand {
    color: #000 !important; }
  .cover-5 h1 {
    color: #000;
    font-weight: 200; }
  .cover-5 p.lead {
    margin: 2rem auto;
    color: #696b6e;
    font-family: "Droid Serif", Times, Georgia, serif;
    font-style: italic; }
  .cover-5 .cover-container {
    display: table;
    height: 100%;
    min-height: 34rem;
    margin: 0 auto; }

.cover-inner {
  display: table-cell;
  vertical-align: middle; }

.features-1 {
  padding: 5rem 0; }
  .features-1 h2 {
    margin-top: 2rem; }
  .features-1 .col-feature {
    padding-top: 2rem;
    padding-bottom: 2rem; }
    .features-1 .col-feature .rounded-circle {
      margin: 0 auto;
      margin-bottom: 2rem;
      width: 6rem;
      height: 6rem;
      background: none;
      border: 0.15rem solid #dfe1e5; }

.features-4 {
  background: #4bc1f0; }
  .features-4 h2 {
    margin-top: 2rem;
    color: #fff; }
  .features-4 p.lead {
    color: #fff; }
  .features-4 .col-feature {
    padding-top: 2rem;
    padding-bottom: 2rem; }
    .features-4 .col-feature h4 {
      color: #fff; }
    .features-4 .col-feature p {
      color: #fff; }
    .features-4 .col-feature .rounded-circle {
      margin: 0 auto;
      margin-bottom: 2rem;
      width: 6rem;
      height: 6rem;
      background: none;
      border: 0.15rem solid #fff;
      color: #fff;
}

.content-1, .content-2, .content-4 {
  background: #fff;
  padding-bottom: 0.1rem; }
  .content-1 .container, .content-2 .container, .content-4 .container {
    margin: 5rem auto; }
  .content-1 .full-width, .content-2 .full-width, .content-4 .full-width {
    width: 100%; }

.cta-2 {
  color: #46484a;
  padding: 5rem 0; }
  .cta-2 p {
    color: #696b6e; }
  .cta-2 form.subscribe {
    max-width: 40rem;
    margin: 0 auto; }
  .cta-2 .btn {
    width: 100%; }

.cta-4 {
  background: #2b2d34 url(../images/cover-bg-3.jpg) center;
  background: #2b2d34;
  background-size: cover;
  color: #fff;
  padding: 5rem 0;
  min-height: 50rem; }
  .cta-4 p {
    color: rgba(255, 255, 255, 0.75); }
  .cta-4 .form-control-custom {
    border: none;
    background: rgba(255, 255, 255, 0.2);
    color: #fff; }
  .cta-4 .form-control-custom:focus {
    border: none;
    background: rgba(255, 255, 255, 0.3);
    color: #fff; }

.cta-5 {
  color: #46484a;
  padding: 4rem 0; }
  .cta-5 p {
    color: #696b6e; }
  .cta-5 form.subscribe {
    max-width: 40rem;
    margin: 0 auto; }
  .cta-5 .btn {
    width: 100%; }

.contact-1, .contact-2 {
  padding: 0 0 5rem 0; }
  .contact-1 .contact-details, .contact-2 .contact-details {
    padding-top: 5rem;
    padding-bottom: 1rem; }
  .contact-1 h3, .contact-2 h3 {
    margin-bottom: 0; }

#map {
  height: 400px;
  width: 100%; }

.footer-2 {
  background: #2b2d34;
  min-height: 5rem;
  width: 100%;
  color: #fff;
  padding-top: 2rem;
  padding-bottom: 1.5rem; }
  .footer-2 a.navbar-brand {
    color: #fff !important; }
  .footer-2 .nav-footer .nav-link {
    color: #dfe1e5; }
  .footer-2 .nav-footer .nav-link:hover {
    color: #59a0d7; }

.footer-3 {
  background: #f7f7f8;
  width: 100%;
  color: #46484a;
  padding-top: 1rem;
  padding-bottom: .5rem; }
  .footer-3 .container-fluid {
    padding: 0; }
  .footer-3 .divider {
    width: 100%;
    height: .1rem;
    margin: .5rem 0 1.5rem 0; }
  .footer-3 .nav-footer {
    width: 100%;
    padding: 0;
    display: flex;
    justify-content: center; }
    .footer-3 .nav-footer .nav-link {
      color: #696b6e; }
    .footer-3 .nav-footer .nav-link:hover {
      color: #59a0d7; }
  .footer-3 .social li a {
    background: none;
    border: none; }

.footer-4 {
  background: #4bc1f0;
  width: 100%;
  color: #fff;
  padding-top: 2rem;
  padding-bottom: 0rem; }
  .footer-4 .container-fluid {
    padding: 0; }
  .footer-4 .divider {
    background: #46484a;
    width: 100%;
    height: .1rem;
    margin: 2rem 0 1.5rem 0; }
  .footer-4 ul.nav-footer {
    float: left !important;
    padding: 0;
    width: 100%; }
    .footer-4 ul.nav-footer li {
      display: block;
      float: left;
      margin-top: .5rem;
      width: 100%; }
    .footer-4 ul.nav-footer .nav-link {
      color: white;
      padding: 0; }
    .footer-4 ul.nav-footer .nav-link:hover {
      color: #59a0d7; }
  .footer-4 .copyright {
    color: #a0a0a0; }
  .footer-4 .social li a {
    background: none;
    color: #f7f7f8;
    border: none; }
  .footer-4 .social li a:hover {
    color: #59a0d7; }
  @media screen and (max-width: 768px) {
    .footer-4 .nav-footer {
      width: auto;
      display: inline; } }

.nav-footer {
  list-style: none;
  float: right; }
  .nav-footer li {
    float: left;
    margin-top: .5rem; }

.carousel-inner img {
  width: 100%;
  height: 100%;
}

.carousel-shadow {
  box-shadow: 0rem 0.5rem 2rem 0rem steelblue;
}

.carousel-shadow-top {
  box-shadow: 0rem -0.5rem 2rem 0rem steelblue;
}

.carousel-indicators > li.active {
  background-color: #4bc1f0;
}

.carousel-indicators > li {
  background-color: #666;
}

@media screen and (max-width: 768px) {
  .nav-footer {
    width: 100%;
    padding: 0;
    display: flex;
    justify-content: center; } }


/* NEW CSS AREA */

.fixed-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  background-color: white;
}

.header-shadow {
  box-shadow: 0rem 1rem 2rem -1rem #666;
}